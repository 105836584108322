.mainnn_cont_business,
.mainn_container_person,
.mainn_container_phone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
}

.businessName_inputlabel,
.businessaddress_inputlabel,
.contact_person_inputlabel,
.empty_inputlabel,
.email_address_input,
.phone_number_input,
.gst_number_input {
  width: calc(33.33% - 20px); 
  margin: 10px;
}

.inputs_styles_ {
  font-family: Medium;
  color: #000;
  font-size: 13px;
  width: 100%;
}

.txtinputs_styling {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
  color: #000;
}

.txtinputs_styling_empty {
  background-color: #e0e0e0;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .mainnn_cont_business,
  .mainn_container_person,
  .mainn_container_phone {
    flex-direction: column;
  }

  .businessName_inputlabel,
  .businessaddress_inputlabel,
  .contact_person_inputlabel,
  .empty_inputlabel,
  .email_address_input,
  .phone_number_input,
  .gst_number_input {
    width: calc(100% - 20px); 
  }
}

/* .css-13tbepd-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: red; 
} */