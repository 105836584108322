/* PrivacyPolicy.css */

/* p {
  word-wrap: break-word; 
  overflow-wrap: break-word;
  white-space: normal; 
  margin: 0; 
}

table {
  width: 100%; 
  table-layout: fixed; 
}

td {
  word-wrap: break-word; 
  white-space: normal; 
  padding: 8px; 
} */

.policy-container {
  font-family: 'Medium';
  font-size: 13.5px;
  letter-spacing: 1.3px;
  color: #5f727f;
  padding: 12px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-container > .content-wrapper {
  max-width: 940px;
  text-align: left;
}

.policy-container > .content-wrapper > h1 {
  font-family: 'Medium';
  font-size: 22.5px !important;
  letter-spacing: 2px;
  color: #3c3950;
}

.policy-container > .content-wrapper > table,
.policy-container > .content-wrapper > table > thead > tr > th,
.policy-container > .content-wrapper > table > tbody > tr > td {
  border-color: #dfe5e8 !important;
  border-style: solid;
  border-collapse: collapse;
}

.policy-container > .content-wrapper > table > thead > tr > th {
  display: table-cell;
  text-align: initial;
}

.policy-container > .content-wrapper > table > thead > tr > th,
.policy-container > .content-wrapper > table > tbody > tr > td {
  text-indent: 5px;
}

.policy-container > .content-wrapper > table > thead > tr > th > p,
.policy-container > .content-wrapper > table > tbody > tr > td > p {
  color: #000000 !important;
}

@media (max-width: 768px) {
  p {
    font-size: 14px; /* Adjust font size for smaller screens if necessary */
  }
}
