@media only screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1700px !important;
  }
}

.dropdown-toggle::after {
  border: none;
  font: normal normal normal 12px/1 FontAwesome;
  content: '\f078';
  vertical-align: 0;
  margin-left: 5px;
}
.form-control {
  background-clip: initial;
  border-radius: 0px;
}
.btn {
  border-radius: 0px;
}
.top-btn-apply-for-insurance .dropdown-toggle::after {
  display: none;
}
.menu-holder {
  width: 100%;
  height: 55px;
  background: white;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  padding: 0px 130px;
}

.header_logo {
  width: 100%;
  height: 55px;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
}

#navbarSupportedContent .btn-blue {
  color: white;
  /* font-family: Source Sans Pro; */
  font-size: 14px;
  opacity: 1;
  text-align: left;
  background-color: #006d9e;
  opacity: 0.9;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  display: inline-block;
  padding: 8px 20px;
}

.top-navigation .navbar-collapse.show {
  background: white;
  padding: 10px;
  z-index: 100;
}

.top-navigation .navbar-collapse.show .menu-container {
  width: 100%;
  padding: 10px;
}

.top-navigation .navbar-collapse.show .menu-container .menu-div {
  min-height: 48px !important;
}

body {
  height: 100%;
  background-color: #e5e5e5;
  font-family: Arial !important;
}

.page_container {
  width: 100%;
  height: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

/* .container {
    border: 0px;
    top: 0px;
    background-color: white;
} */

.header-top {
  background-color: #ececec;
  min-height: 40px;
  padding: 0px 130px;
}

.header span {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: right;
  color: #192934;
}

.top-email {
  display: inline-block;
}

.footer-copyright {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #ffffff;
}

.row-copyright {
  background: #002c77;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0px 10%;
}

.footer-text-area {
  background-color: #f3f6ff;
  color: #000;
  font-size: 18px;
  padding: 40px 10%;
  /* font-family: Source Sans Pro; */
  line-height: 28px;
}

.footer-text.text1 {
  margin-left: 30px;
}

.footer-copyright-holder {
  text-align: left;
}

.footer-contact-holder {
  text-align: right;
}

.footer-contact-holder a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  text-decoration: underline;
}

.col-footer {
  padding: 0px;
}

.body-top-image {
  background-image: url('..../../../../../../../../public/Content/images/index-body-top.jpg');
  background-size: cover;
  max-width: 100% !important;
}

.top-content-title {
  width: 506px;
  margin-left: 135px;
  padding-top: 84px;
}

.double-underline {
  vertical-align: top;
}

.underline_PE {
  width: 480px;
}

.top-content-main-text-area {
  color: white;
}

.top-content-text p {
  margin-bottom: 0px;
}

.btn-contactus {
  width: 134px;
  height: 44px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0px;
  border: 0px;
  background: linear-gradient(97.69deg, #00a8c8 0%, #006d9e 100%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.contact-us-btn-label {
  margin-right: 12px;
}

.contact-us-arrow {
  font-size: 12px;
}

.insurance-experts {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 94.9%;
  color: white;
  margin-top: 80px !important;
  margin-bottom: 60px !important;
  margin-left: 0px !important;
}

.body-top-content {
  color: white;
}

.right-box-menu {
  max-width: 370px;
  height: 136px;
  margin-top: 30px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 103.9%;
  /* identical to box height, or 25px */
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10% 5%;
  cursor: pointer;
  color: white !important;
  text-decoration: none !important;
}

.right-box-link:hover {
  text-decoration: none !important;
}

.right-box-menu.first {
  margin-top: 55px;
  background: linear-gradient(98.25deg, #00a8c8 0%, #006d9e 100.93%);
  opacity: 0.9;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.right-box-menu.second {
  background: linear-gradient(98.25deg, #85d459 0%, #0fb694 100.93%);
  opacity: 0.9;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.right-box-menu.third {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  opacity: 0.9;
  /* Drop Shadow */
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 30px;
}

.body-middle-content .title {
  height: 45px;
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 93px;
  top: 193px;
}

.body-middle-content .title-column {
  max-width: 300px !important;
}

.title.bold {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  letter-spacing: 0.01em;
  color: #192934;
}

.title.normal {
  font-weight: normal;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  letter-spacing: 0.01em;
  color: #192934;
}

.contact-us .title {
  width: 310px;
}

.box.image {
  padding-top: 84px;
  padding-left: 300px;
  position: absolute;
}

.body-middle-content .title-text {
  /* font-family: Source Sans Pro; */
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: 0.01em;
  text-align: center;
  padding-top: 90px !important;
  margin-bottom: 60px !important;
}

.body-bottom-content .title-text {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 103.9%;
  /* or 37px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #192934;
  padding-top: 90px !important;
  margin-bottom: 60px !important;
  margin-left: 0px !important;
}

.body-middle-content-underline {
  width: 300px;
  margin-top: -20px;
}

.body-middle-conent-text {
  display: block;
  /* font-family: Source Sans Pro; */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.01em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  padding-bottom: 30px;
}

.body-middle-content .box-container {
  padding: 10px;
}

.body-middle-content .box {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 0px 10px 20px 10px;
  height: 100%;
  position: relative;
}

.body-middle-content .box .anchor-button {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10px;
}

.btn-view {
  background: #ffffff;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.04);
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 103.9%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  color: #00a8c8;
  margin-top: 15px;
  border: 1px solid #00a8c8;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  margin: 0px auto;
}

.drop-btn {
  margin-left: 0 !important;
}

.view-arrow {
  font-size: 11px;
  font-weight: bold;
  margin-left: 5px;
}

.body-middle-content {
  background-color: rgba(166, 226, 239, 0.3);
}

.body-bottom-content-underline {
  width: 173px;
  height: 5px;
  margin-bottom: 25px;
}

.body-bottom-content .box.contact-us {
}

.body-bottom-content-image {
  width: 100%;
}

.body-bottom-content .box {
}

.boxs-holder {
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/* .page {
    max-width: 1440px;
    width: 100%;
} */

.header {
  width: 100%;
}

.body {
  width: 100%;
}

.footer {
  width: 100%;
  max-width: 100%;
}

.right-box-menu-content-holder {
}

.right-box-menu-icon {
  margin-left: 27px;
  margin-right: 19px;
}

.right-box-menu-arrow {
  height: 27px;
  margin-right: 20px;
}

.top-login {
  padding-top: 1px;
}

.header-top-left-content .virtical-line {
  margin-left: 8px;
  margin-right: 8px;
}

.top-login-holder {
  text-align: right;
}

.top-navigation {
  background-color: white !important;
  height: 55px;
  padding-left: 0px;
  padding-right: 0px;
}

.body-bottom-content .box {
  display: inline-block;
}

.body-bottom-content .content-holder {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
}

.body-bottom-content .image {
  margin-left: 40px;
}

.contact-img {
  margin-top: 30px;
}

.body-bottom-content {
  min-height: 450px;
}

.body-bottom-content.contact-us {
  background: white;
}

.footer-text-icon {
  color: #a6e2ef;
}

.footer-login-btn {
  background-color: #72be44;
  border: 0px;
  width: 100%;
  max-width: 121px;
  min-height: 40px;
}

.footer-marsh-img {
  width: 100%;
  max-width: 121px;
}

.top-phone-number {
}

/* css by pages*/

.top-content-main-text-area_Common {
  width: 100%;
}

.body-top-image_PI {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_PI.jpg');
}

.body-top-image_BP {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_BP.png');
}

.body-top-image_PE {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_PE.png');
}

.body-top-image_LI {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_LI.png');
}

.body-top-image_PB {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_PB.png');
}

.body-top-image_Marine {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_Marine.jpg');
}

.body-top-image_Motor {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_Motor.jpg');
}

.body-top-image_EB {
  width: 1440px;
  background-size: cover;
  background-image: url('..../../../../../../../../public/Content/images/index-body-top_EB.png');
}

.insurance-experts_PI {
  font-size: 48px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  line-height: 94.9%;
  color: white;
  margin-top: 30px !important;
}

.overlay-heading-text {
  font-size: 48px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  line-height: 94.9%;
  margin-bottom: 30px !important;
  color: black;
}
.navbar-containerr {
  background-color: #fff;
  height: '60px';
}

.imagesizedifferscreen_navbarrr {
  max-width: 100%;
  height: auto;
}
.text-containerrrr-navbarrrrr {
  display: flex;
  justify-content: space-between;
  font-family: sans-serif;
  color: #000;
  font-size: 13px;
}
.optionsss-styling-navbarrrr {
  cursor: pointer;
  margin: 0 10px;
  font-family: sans-serif;
  color: #000;
  font-size: 15px;
}
.selectedddd-route-textt {
  font-weight: bold;
  color: #000;
}
.buttonStyling_navbarrrr {
  margin-right: 10px;
}
.buttonStyling_navbarrr_agentportal {
  background-color: #007bff;
  color: #fff;
}

.overlay {
  background-color: rgba(247, 247, 247, 0.9) !important;
}

.insurance-question_PI {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 103.9%;
  /* or 37px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.form-row_PI {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 103.9%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  color: #ffffff;
  margin: 0px auto;
  margin-left: 20%;
  margin-right: 20%;
}

.top-content-text_BP {
  width: 100%;
  margin-top: 24px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #3f3d56;
  padding: 40px 100px;
  background: #f7f7f7;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.top-content-text_EB {
  width: 100%;
  margin-top: 24px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #3f3d56;
  padding: 40px 100px;
  background: #f7f7f7;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.top-content-text_LI {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f7f7f7;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.top-content-text_PE {
  width: 100%;
  margin-top: 24px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #3f3d56;
  padding: 40px 100px;
  background: #f7f7f7;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.top-content-text_PI {
  width: 100%;
  margin-top: 24px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  color: #3f3d56;
  padding: 40px 100px;
  background: #f7f7f7;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.align-center {
  text-align: center;
}

.body-middle-content_PI {
  background-color: #ffffff;
  width: 1441px;
}

.body-bottom-content_PI {
  background-color: rgba(166, 226, 239, 0.2);
}

.body-bottom-content_BP {
  background-color: #ffffff;
  width: 1441px;
}

.body-bottom-content_PE {
  background-color: #ffffff;
  width: 1441px;
}

.body-bottom-content2_PI {
  background-color: #002c77;
  width: 1440px;
}

.boxs-holder_PI {
  padding-top: 20px;
}

..body-middle-content_PI .box-wrapper {
  padding: 10px;
}

.body-middle-content_PI .box {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  position: relative;
}

.body-middle-content_PI .box img {
  width: 100%;
}

.first_image_PI {
  width: 44px;
  height: 44px;
  background: linear-gradient(98.03deg, #79c14d 0%, #0fb694 100.55%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.top-content-main-navi {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.top-content-main-navi i {
  margin-left: 5px;
  margin-right: 5px;
}

.blue-navigation span {
  color: #006d9e;
}

.top-content-title-PI {
  margin: 40px auto !important;
  color: #ffffff;
  font-size: 36px;
}

.bottom-content-title_COMMON {
  padding-top: 60px !important;
  margin-bottom: 30px !important;
  font-size: 36px;
  letter-spacing: 0.01em;
  color: #192934;
}

.body-bottom-content-underline-PI {
  width: 276px;
  height: 4px;
  vertical-align: top;
}

.body-bottom-content-underline-EB {
  width: 262px;
  height: 4px;
  vertical-align: top;
}

.body-bottom-content-holder_PI {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.body-bottom-content-holder_PI .row {
  height: 115px;
}

.body-bottom-content-holder_PI .row .col .first_image_PI {
  margin-left: auto;
  margin-right: auto;
}

.first_image_person1_PI {
  padding-left: 95px;
}

.first_image_bed1_PI {
  padding-right: 95px;
}

.body-middle-conent-otherInsuranceTxt {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 103.9%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 25px;
  letter-spacing: 0.01em;
}

.body-middle-conent-otherInsuranceTxt_HS {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 103.9%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 25px;
  letter-spacing: 0.01em;
}

.top-content-title-PE {
  width: 480px;
  margin-top: 43px;
  /*83-16-24*/
  margin-left: auto;
  margin-right: auto;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  /*    font-size: 48px;*/
  line-height: 94.9%;
  /* identical to box height, or 46px */
  text-align: center;
  color: #ffffff;
}

.top-content-title-BP {
  width: 520px;
  margin-top: 43px;
  /*83-16-24*/
  margin-left: auto;
  margin-right: auto;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  /*    font-size: 48px;*/
  line-height: 94.9%;
  /* identical to box height, or 46px */
  text-align: center;
  color: #ffffff;
}

.top-content-title-Marine {
  width: 270px;
}

.top-content-title-AFCVI {
  width: 700px;
  margin-top: 5px;
}

.top-content-title-AFDH {
  width: 560px;
  margin-top: 5px;
}

.body-bottom-content-holder_BP {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0px;
}

.body-bottom-content-holder_BP .col {
  margin-bottom: 30px;
  min-width: 25%;
}

.body-bottom-content-holder_EB {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.body-bottom-content-holder_EB .row {
  height: 140px;
}

.body-middle-conent_BP_InsuType {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 103.9%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  display: block;
  line-height: 25px;
  letter-spacing: 0.01em;
}

.body-middle-conent-btn_PI {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  margin-left: 10px;
}

.pi-button {
  position: absolute;
  bottom: 10px;
}

.body-middle-conent-text_PI {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  width: 100%;
  color: #192934;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 10px;
}

.body-middle-conent-text-body_PI {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #192934;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 100px;
  text-align: left;
  margin-bottom: 40px;
}

.body-bottom-content-btn_COMMON {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  color: #fff;
  border-color: #dc3545;
  margin-top: 20px;
  margin-left: 20%;
  margin-bottom: 20px;
}

.body-bottom-content-btn_ApplyForMotor {
  margin-left: 0px;
}

.claiminfo-body-content {
  width: 1169px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f4f5;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 181px;
  padding-right: 181px;
}

.claiminfo-body-content_Maid {
  padding-bottom: 200px;
}

.claiminfo-body {
}

.claiminfo-footer {
}

.claiminfo-body-content-holder {
  width: 1440px;
  height: 100%;
  background-image: url(..../../../../../../../../public/Content/images/claiminfo-bg.png);
  background-repeat: no-repeat;
  background-color: white;
  padding-top: 60px;
}

.claiminfo-header {
}

.claiminfo-heder-top {
}

.claiminfo-body-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 103.9%;
  /* or 37px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #192934;
}

.top-content-title-CI {
  margin-top: 5px;
}

.claiminfo-dropdown-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 103.9%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  margin-top: 34px;
  margin-bottom: 10px;
  color: #192934;
}

.claiminfo-dropdown {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 370px;
  margin-bottom: 40px;
}

.claiminfo-dropdown-btn {
  text-align: left;
  width: 100%;
}

.claiminfo-dropdown-btn.dropdown-toggle::after {
  border: none;
  font: normal normal normal 12px/1 FontAwesome;
  content: '\f078';
  vertical-align: 0;
  float: right;
  margin-top: 5px;
}

.claiminfo-subtitle {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  margin-bottom: 24px;
  color: #3f3d56;
}

.claiminfo-content {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */
  letter-spacing: 0.01em;
  margin-bottom: 32px;
  color: #192934;
  word-break: break-word;
}

.claim-dropdown-menu {
  width: 100% !important;
}

.claiminfo-content-paragraph-green {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #72be44;
}

.claiminfo-content-paragraph-red {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #ed2c67;
}

.claiminfo-mr {
  line-height: 26px;
}

.claiminfo-content-paragraph-subtitle {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  margin-top: 28px;
  margin-bottom: 28px;
  color: #192934;
}

.claiminfo-content-paragraph-icon {
  max-width: 80px;
}

.claiminfo-circle-text {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height, or 141% */
  color: #ffffff;
}

.claiminfo-content-paragraph-content .single-line {
  margin-top: 10px;
}

.claiminfo-content-paragraph-with-icon {
  margin-bottom: 15px;
}

.confirmpage-body-content {
  height: 420px;
}

.confirmpage-body-content_Motor {
  height: 473px;
}

.confirmpage-body-content-holder {
  width: 1440px;
  height: 100%;
  background-image: url(..../../../../../../../../public/Content/images/claiminfo-bg.png);
  background-repeat: no-repeat;
  background-color: white;
  padding-top: 60px;
  padding-bottom: 250px;
}

.ApplyDomesticHelperInsur-body-content {
  height: 2950px;
}

.ApplyMotorInsur-body-content {
  height: 2260px;
}

.ApplyMotorInsur-body-content-holder {
  padding-bottom: 1px;
  padding: 60px 130px;
}

.confirmpage-body-content-text {
  background: #ffffff;
  /* sapphire / bright */
  border: 1px solid #00a8c8;
  box-sizing: border-box;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  /* or 141% */
  color: #3f3d56;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.confirmpage-body-content-text_Motor {
  margin-top: 40px;
}

.confirmpage-body-text {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103.9%;
  /* or 19px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.confirmpage-body-text_Motor {
}

.confirmpage-body2-text {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 60px;
}

.confirmpage-body2-text_Travel {
  font-size: 16px;
}

.double-underline-AFMIS {
  width: 458px;
  margin-top: 5px;
  height: 4px;
}

.double-underline-AFCIS {
  width: 480px;
  margin-top: 5px;
  height: 4px;
}

.double-underline-AFCVI {
  width: 615px;
  margin-top: 5px;
  height: 4px;
}

.double-underline-AFDH {
  width: 565px;
  margin-top: 5px;
  height: 4px;
}

.double-underline-AFTI {
  width: 405px;
  margin-top: 5px;
  height: 4px;
}

.insurance-form-body {
  margin-top: 32px;
}

.insurance-form-body.container {
  background-color: inherit;
}

.insurance-form-element-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 103.9%;
  /* identical to box height, or 17px */
  letter-spacing: 0.01em;
  color: #000000;
  display: block;
}

.red-star {
  color: #ed2c67;
}

.red-star-padding {
  color: #ed2c67;
  padding-top: 10px;
}

.insurance-form-element-full {
  width: 370px;
}

.insurance-form-sub-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.insurance-form-sub-title_blue {
  color: #006d9e;
}

.insurance-form-sub-title_2 {
  font-size: 14px;
  margin-bottom: 10px;
}

.insurance-form-sub-title_3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}

.insurance-form-sub-title_4 {
  font-size: 12px;
  font-weight: bold;
}

.insurance-form-sub-title_5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.insurance-from-sub-title-col {
  padding-left: 15px;
  margin-top: 10px;
}

.form-control.insurance-form-element-smallest {
  min-width: 80px;
  width: 22%;
  display: inline-block;
}

.form-control.insurance-form-element-1 {
  display: inline-block;
  width: 70%;
}

.form-control.insurance-form-element-2 {
  display: inline-block;
  width: 80px;
  margin-right: 10px;
}

.form-control.insurance-form-element-3 {
  display: inline-block;
  width: 80px;
  margin-right: 0px;
}

.insurance-form-element-4 {
  display: inline-block;
  width: 370px;
}

.insurance-form-element-5 {
  display: inline-block;
  width: 307px;
}

.insurance-form-element-6 {
  width: 170px;
  display: inline-block;
}

.insurance-form-element-7 {
  width: 760px;
  display: inline-block;
}

.insurance-form-element-8 {
  width: 620px;
  display: inline-block;
}

.insurance-form-element-9 {
  width: 192px;
  display: inline-block;
}

.SingleBar {
  margin-top: 20px;
  width: 100%;
  height: 0px;
  left: 335px;
  top: 1236px;
  border: 1px solid #e8e8e8;
}

.SingleBar_0 {
  margin-top: 0px;
  width: 100%;
  height: 0px;
  left: 335px;
  border: 1px solid #e8e8e8;
}

.applyTravel-body-content {
  width: 1169px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f4f5;
  padding: 20px;
}

.applyTravel-body-content-btn_COMMON {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  height: 40px;
  left: 235px;
  top: 629px;
  background: #72be44;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
}

.applyTravel-body-content-btn_COMMON_2 {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #ffffff;
  background: #006d9e;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
}

.applyTravel-body-content-element-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 103.9%;
  /* identical to box height, or 17px */
  width: 110px;
  letter-spacing: 0.01em;
  color: #000000;
  display: block;
}

.insurance-form-sub-content {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  color: #3f3d56;
}

.style-alpha {
  list-style-type: lower-alpha;
}

.inline-textbox-with-label {
  display: inline-block;
}

.insurance-form-sub-checkboxTitle {
  font-size: 14px;
  /*font-weight: bold;    */
}

.insurance-form-sub-checkboxTitle_2 {
  font-size: 12px;
  /*font-weight: bold;    */
}

.additional-benefits-detail.container {
  background-color: transparent;
  padding-left: 0px;
}

.additional-benefits-detail-span {
  padding-left: 10px;
}

.applyConfirm-body-content {
  width: 1169px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #f0f4f5;
  padding: 20px;
}

.promotions-body-content-holder {
  padding-bottom: 30px;
}

.promotion-body-content {
  padding: 20px 140px;
}

.top-content-title-PWN {
  font-size: 36px;
}

.bottom-content-title-PWN {
  font-size: 36px;
  margin: 30px auto !important;
}

.promotion-top-banner {
  margin-top: 30px;
}

.promotion-top-banner img {
  width: 100%;
  height: 100%;
}

.promotion-top-banner p {
  margin-bottom: 0px;
}

.promotion-top-banner .discount-percent {
  color: #ed2c67;
}

.promotion-top-banner .discount-text {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
}

.promotion-body-content .valid-date {
  color: #006d9e;
}

.promotion-body-content .valid-text1 {
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
}

.promotion-body-content .valid-text2 {
  font-size: 14px;
  line-height: 18px;
}

.promotion-body-content .page-button {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  color: white;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 103.9%;
  outline: none !important;
  outline: none;
  border: none;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.promotion-body-content .page-button .fas {
  margin-left: 10px;
}

.promotion-body-content .fa-clock {
  margin-right: 5px;
}

.promotion-adds {
  display: none;
}

.promotion-adds .add-container {
  margin-top: 40px;
  position: relative;
}

.promotion-adds .add-container img {
  width: 100%;
}

.promotion-adds .add-container .heading {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  margin-top: 10px;
}

.promotion-adds .add-container .heading .highlight {
  color: #ed2c67;
}

.promotion-adds .add-container .bottom-btn {
  position: absolute;
  bottom: 0px;
}

.white-bg {
  background-color: #ffffff;
  height: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.white-bg div {
  padding: 0px 10px;
}

.bottom-title {
  margin-top: 46px;
  margin-bottom: 32px;
}

.update-container {
  padding: 60px;
  background-color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  /* font-family: Source Sans Pro; */
  color: #006d9e;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

/*Domestic Helper*/

.body-top-imageDomesticHelper {
  background-image: url(..../../../../../../../../public/Content/images/DomesticHelperBg.png);
  background-size: cover;
}

.DH-row {
  background-color: #f7f7f7;
  padding: 40px 180px;
}

.DH-box-center {
  background-color: white;
  width: 100%;
  padding: 40px;
}

.top-content-text_DH {
  background: #f7f7f7;
  opacity: 0.95;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  margin-left: 135px;
  margin-right: 135px;
  margin-top: 20px;
  margin-bottom: 48px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 100px;
  padding-right: 100px;
  color: black;
}

.title-48px {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 94.9%;
  /* identical to box height, or 46px */
  text-align: center;
  display: block;
}

.top-content-title-DH {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.DH-top-title-content {
  width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.top-content-main-navi-DH span {
  color: #006d9e;
}

.top-content-main-navi-DH i {
  color: black;
}

.title-left {
  margin-left: 18px;
  height: 45px;
  margin-bottom: 20px;
  padding-top: 0px;
}

.DH-title-text {
  font-size: 36px;
  margin-bottom: 28px !important;
  float: left;
}

.title-left-underline-DH {
  width: 260px;
}

.body-bottom-content2_DH {
  width: 1440px;
  padding: 1px;
}

.benefits-and-premium {
  margin-bottom: 30px;
}

.benefits-and-premium .title-left {
  max-width: 359px;
  width: 100%;
}

.benefits-and-premium .title-left-underline-DH {
  max-width: 335px;
  width: 100%;
}

.proposal-form {
  text-align: right;
  text-decoration: underline;
}

.proposal-form span {
  margin-top: 10px;
}

.benefits-and-premium .download {
  color: #00a8c8;
  font-size: 20px;
  margin-right: 5px;
}

.benefit-row .title {
  margin-bottom: 15px;
}

.plan-title-1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #0fb694;
}

.plan-title-2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #00a8c8;
}

.plan-title-3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #ed2c67;
}

.plan-title-4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #ed42dc;
}

.benefit-content {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 103.9%;
  /* identical to box height, or 25px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #192934;
}

.premium-value {
  display: block;
  /* margin-left: 10px;
    margin-right: 10px; */
  background-color: #f0f4f5;
  padding-left: auto;
  padding-right: auto;
  padding-top: 36px;
  padding-bottom: 20px;
  text-align: center;
}

.premium-title {
  display: block;
  padding-top: 36px;
}

.premium-value-last-line {
  padding-bottom: 30px;
}

.premium-value-line {
  display: block;
  /* margin-left: 10px;
    margin-right: 10px; */
  background-color: #f0f4f5;
  /* padding-left: 30px;
    padding-right: 42px; */
  padding-left: 0;
  padding-right: 0;
}

.premium-value-2 {
  display: block;
  /* margin-left: 10px;
    margin-right: 10px; */
  background-color: #f0f4f5;
  padding-left: auto;
  padding-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.premium-value-line-last {
  display: block;
  /* margin-left: 10px;
    margin-right: 10px; */
  background-color: #f0f4f5;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
}

.DH-btn-buy-now-1 {
  background: linear-gradient(98.03deg, #79c14d 0%, #0fb694 100.55%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  width: 100%;
  border: 0px;
  width: 100%;
  height: 41px;
  margin-left: 0;
}

.DH-benefits-and-premium-btn-area {
  margin-top: 24px;
}

.DH-benefits-and-premium-btn-col {
  display: block;
  /* margin-right: 20px; */
}

.DH-btn-buy-now-2 {
  background: linear-gradient(97.69deg, #00a8c8 0%, #006d9e 100%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  border: 0px;
  width: 100%;
  height: 41px;
  margin-left: 0;
}

.DH-btn-buy-now-3 {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  width: 100%;
  border: 0px;
  height: 41px;
  margin-left: 0;
}

.DH-btn-buy-now-4 {
  background: linear-gradient(98.25deg, #ed42dc 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  width: 100%;
  border: 0px;
  height: 41px;
  margin-left: 0;
}

.DH-long-line {
  display: inline-block;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #0fb694;
}

.DH-long-line.DH-blue {
  border-bottom: 1px solid #00a8c8;
}

.DH-long-line.DH-red {
  border-bottom: 1px solid #ed2c67;
}

.DH-section-divider-text-col {
  text-align: center;
}

.DH-section-divider-text {
  display: block;
  margin-top: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  letter-spacing: 0.01em;
  color: #192934;
}

.DH-section-divider-right {
  padding-right: 23px;
}

.DH-section-divider-row {
  margin-top: 26px;
  margin-bottom: 26px;
}

.benefit-gray-title-in-section {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f4f5;
  min-height: 39px;
  height: 100%;
  padding-top: 10px;
  padding-left: 19px;
}

.benefit-gray-title-in-section.DH-long-title {
  padding: 15px;
}

.benefit-gray-content-in-section {
  display: block;
  /* margin-left: 10px;
    margin-right: 10px; */
  background-color: #f0f4f5;
  min-height: 39px;
  height: 100%;
  padding-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #192934;
}

.benefit-gray-content-in-section.left {
  text-align: left !important;
}

.benefit-gray-content-in-section.long-text {
  padding: 10px;
}

.benefit-title-in-section {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 39px;
  padding-top: 10px;
  padding-left: 19px;
}

.benefit-gray-indent-title-in-section {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f4f5;
  min-height: 39px;
  height: 100%;
  padding-top: 10px;
  padding-left: 49px;
  padding-bottom: 10px;
}

.benefit-indent-title-in-section {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 39px;
  height: 100%;
  padding-top: 10px;
  padding-left: 49px;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: bold;
}

.text-margin-bottom-space {
  margin-bottom: 10px;
}

.benefit-content-in-section {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  height: 39px;
  padding-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  text-align: center;
  letter-spacing: 0.01em;
  color: #192934;
}

.benefit-sub-title-big {
  font-weight: bold;
  font-size: 14px;
  line-height: 103.9%;
  /* identical to box height, or 15px */
  letter-spacing: 0.01em;
  color: #192934;
  margin-bottom: 8px;
}

.block-span {
  display: block;
}

.text-center {
  text-align: center;
}

.top-up-hospitalization-and-surgical-expenses {
  height: 30px;
}

.bg-gray {
  height: 100%;
  background-color: #f0f4f5;
}

.sub-row {
  height: 100%;
}

.position-bottom-holder {
  position: relative;
}

.position-bottom {
  position: absolute;
  bottom: 0px;
}

.width-80percent {
  width: 80%;
}

.benefit-bottom-text {
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #192934;
  margin-bottom: 12px;
}

.bottom-button-area {
  text-align: center;
  margin-top: 40px;
}

.bottom-button-area-row {
}

.bottom-btn1 {
  width: auto !important;
  height: 41px;
}

.bottom-btn2 {
  text-decoration: underline;
}

.bottom-or {
  margin-left: 40px;
  margin-right: 40px;
}

.top-title-FAQ {
  width: 80px;
}

.top-title-UsefulLinks {
  margin-bottom: 30px !important;
}

.faq-question-title {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 103.9%;
  /* identical to box height, or 19px */
  letter-spacing: 0.01em;
  color: #006d9e;
  padding-left: 0px;
  cursor: pointer;
}

.faq-question {
  border-bottom: 0px;
  background-color: transparent;
  padding-left: 0px;
}

#accordion faq-holder {
}

.faq-content {
  border: 0px;
  background-color: transparent;
}

.faq-question-content {
  padding-left: 0px;
  padding-top: 0px;
}

.faq-arrow {
  border: 0px;
  background-color: transparent;
  color: #006d9e;
}

.faq-arrow-icon {
  width: 16px;
  height: 18px;
}

.no-bg-no-padding {
  padding-left: 0px;
  background-color: transparent;
}

.collapse-all-holder {
  text-align: right;
  margin-top: 10px;
}

.dropdown-holder {
}

.faq-dropdown {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 370px;
  margin-bottom: 10px;
}

.faq-dropdown-btn.dropdown-toggle::after {
  border: none;
  font: normal normal normal 12px/1 FontAwesome;
  content: '\f078';
  vertical-align: 0;
  margin-left: 210px;
}

.faq-dropdown-btn {
  background-color: white;
}

.toggle-accordion:before {
  content: 'Expand All';
}

.toggle-accordion {
  color: #006d9e;
}

.toggle-accordion.active:before {
  content: 'Collapse All';
}

.card-header {
  margin-left: 15px;
}

.aboutus-body-content-holder {
  width: 1440px;
  height: 100%;
  background-image: url(..../../../../../../../../public/Content/images/aboutus_bg.jpg);
  background-repeat: no-repeat;
  background-color: white;
  padding: 60px 10%;
  float: left;
  text-align: center;
  background-size: contain;
}

.title-AS {
  margin-top: 10px;
  margin-bottom: 15px;
}

.aboutus-body-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #f7f7f7;
  padding: 20px;
}

.AS-office {
  position: relative;
  left: 50px;
}

.aboutus-text {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;
  /* or 141% */
  color: #192934;
}

.about-us-contents {
  float: left;
  text-align: left;
}

.span-big-bottom-space {
  display: block;
  margin-bottom: 20px;
}

.heading-wrapper {
  display: table;
  margin: 0px auto;
  padding: 2px;
  text-align: center;
  position: relative;
}

.heading-div {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  margin: 0px auto;
  display: table;
  line-height: 103.9%;
  letter-spacing: 0.01em;
  font-size: 30px;
}

.heading-div::after {
  content: ' ';
  width: 100%;
  /* border-top: 1px solid #006d9e;
  border-bottom: 1px solid #ed2c67; */
  padding: 1px;
  display: inline-block;
  position: absolute;
  bottom: -5px;
  left: 0px;
}

.motor-insurance-body .motor-banner {
  min-height: 400px;
  background-image: url('..../../../../../../../../public/Content/images/white-car-on-the-road-811034%201.png');
  padding: 60px 130px;
  position: relative;
  background-size: cover;
}

.motor-insurance-body .motor-banner .breadcrumbs {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 30px;
  display: inline-block;
}

.motor-insurance-body .motor-banner .motor-overlay {
  min-height: 277px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 20px 160px;
}

.motor-insurance-body .motor-banner .motor-heading {
  font-size: 48px;
}

.motor-insurance-body .motor-banner p {
  font-size: 17px;
  margin-top: 24px;
}

.motor-insurance-body .motor-body {
  background-color: #ffffff;
  padding: 30px 130px;
}

.motor-insurance-body .motor-body .motor-body-heading {
  font-size: 36px;
  margin-bottom: 140px;
}

.motor-insurance-body .insurance-types {
  margin-bottom: 20px;
}

.motor-insurance-body .insurance-types .text-container {
  display: table;
  vertical-align: middle;
  height: 250px;
}

.motor-insurance-body .text-container .text-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.motor-insurance-body .text-wrapper span {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
  display: inline-block;
}

.motor-insurance-body .text-wrapper p {
  font-size: 17px;
}

.motor-insurance-body .insurance-types img {
  width: 100%;
  height: 250px;
}

.motor-insurance-body .motor-body .vehicle-heading {
  font-size: 36px;
  margin-top: 80px;
}

.motor-insurance-body .motor-body .vehicle-icon-container {
  width: 60%;
  margin: 0px auto;
  margin-top: 50px;
  text-align: center;
}

.motor-insurance-body .motor-body .vehicle-icon-container .icon-image {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  float: left;
  min-height: 180px;
}

.motor-insurance-body .motor-body .vehicle-icon-container .icon-image img {
  margin: 0px auto;
  display: block;
}

.clearfix {
  clear: both;
}

.page-button {
  background: linear-gradient(98.25deg, #ed2c67 0%, #cf3d96 100.93%);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  color: white;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 103.9%;
  outline: none !important;
  outline: none;
  border: none;
  height: 40px;
  padding: 0px 20px;
}

.motor-button {
  margin-top: 80px;
  margin-bottom: 10px;
}

.contact-us-title {
  font-size: 48px;
  line-height: 94.9%;
  /* identical to box height, or 46px */
  text-align: center;
  color: #ffffff;
  margin-bottom: 60px;
}

.contactus-body-content-holder {
  /* height: 100%; */
  background-image: url(..../../../../../../../../public/Content/images/contactus_bg.jpg);
  padding: 60px 130px;
  background-repeat: no-repeat;
  background-color: white;
  background-size: contain;
  height: 35vh;
  width: 100%;
  background-size: cover;
  margin-bottom: 310px;
  /* margin-bottom: 250px; */
}

.contact-body-content {
  height: 100%;
  background: transparent;
}

.office-info {
  background-color: white;
  display: inline-block;
  padding: 20px;
  /* margin-bottom: 10px;
  margin-top: 19px; */
  border-radius: 12px;
  width: 32%;
}

.travel-insurance-body .travel-banner {
  min-height: 350px;
  background-image: url('..../../../../../../../../public/Content/images/brown-leather-duffel-bag.png');
  padding: 60px 130px;
  position: relative;
  background-size: cover;
}
.contactus-map {
  width: 100%;
  /* padding: 20px; */
  box-sizing: border-box;
  margin-left: 2%;
}
.contactus-body-content {
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin-left: auto;
}

.travel-insurance-body .travel-banner .breadcrumbs {
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 30px;
}

.travel-insurance-body .travel-banner .travel-overlay {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 30px 160px;
}

.travel-insurance-body .travel-banner .travel-heading {
  font-size: 48px;
}

.travel-insurance-body .travel-banner p {
  font-size: 17px;
  margin-top: 24px;
  margin-bottom: 0px;
}

.travel-mid-content {
  background: radial-gradient(49.93% 49.93% at 50% 50.07%, #ffffff 0%, #f7f7f7 100%);
  padding: 40px 130px;
}

.travel-mid-content-heading {
  font-size: 36px;
  margin-bottom: 30px;
}

.travel-mid-content-section {
  background: #ffffff;
  padding: 40px 80px;
}

.travel-benifit-container {
  font-size: 17px;
  list-style-image: url('..../../../../../../../../public/Content/images/Group_32.png');
}

.travel-benifit-container li {
  position: relative;
  margin-bottom: 5px;
}

.travel-benifit-container span {
  position: absolute;
  top: 2px;
}

.travel-bottom-content {
  background: #006d9e;
  text-align: center;
}

.travel-bottom-content-heading {
  padding-top: 45px;
  margin-bottom: 25px;
}

.travel-bottom-content-heading .heading-div {
  color: #ffffff;
  font-size: 32px;
}

.travel-bottom-content .travel-button {
  margin: 0px auto;
  margin-bottom: 45px;
}

.faq-question-subTitle {
  font-weight: bold;
  font-size: 16px;
  padding-left: 15px;
}

.contactus-map img {
  width: 100%;
  /* margin-left: 4%; */
}

.title-CS {
  margin-top: 10px;
  margin-bottom: 15px;
}

.contactus-sub-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
  letter-spacing: 0.01em;
  /* sapphire / medium */
  color: #006d9e;
}

.contactus-sub-content {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #192934;
}

.contactus-address {
  margin-bottom: 32px;
  line-height: 22px;
  width: 100%;
}

.contactus-address-col {
  padding-left: 0px;
  padding-right: 0px;
}

.text-red {
  color: #ef4e45;
  font-weight: 600;
}

.double-underline-CS {
  width: 210px;
}

.spacer150 {
  height: 150px;
  background: white;
}

.doubleline-contactus-sent-title-CU {
  width: 290px;
  margin-top: 4px;
}

.CU-sent-result {
  width: 70%;
  padding: 100px 60px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  margin: 48px auto;
}

.CU-sent-result-text {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 12px;
}

.CU-sent-result-text2 {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.CU-sent-result-icon {
  color: white;
  font-size: 54px;
}

.contact-sent-container {
  text-align: center;
}

.holder1 {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-body-content-holder {
  padding-bottom: 30px;
}

.page-body-content {
  padding: 20px 140px;
}

.epay-heading {
  font-size: 36px;
}

.epay-payinfo {
  font-weight: bold;
  font-size: 18px;
  margin: 40px auto;
  text-align: center;
}

.epay-form-container {
  margin-top: 40px;
}

.epay-form-container .form-group {
  float: left;
  min-width: 50%;
}

.epay-form-container .text-area-group {
  min-width: 100%;
}

.epay-form-container .text-area-group textarea {
  width: 100%;
  min-height: 150px;
}

.epay-axa-head {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Source Sans Pro';
  margin-top: 80px;
  text-align: center;
}

.epay-follow {
  font-size: 16px;
  text-align: center;
  margin-bottom: 25px;
}

.covid-alert {
  background-color: #006d9e;
  color: white;
  padding: 2% 20%;
  margin-bottom: 0px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.covid-alert span {
  opacity: 1 !important;
  color: white !important;
  font-size: 30px;
}

.covid-alert-heading {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

.promotion-body-content .page-button.width125 {
  width: 125px;
}

.buisness-menu {
  border-left: 1px solid #a6e2ef;
}

.insurance-menu-container {
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  float: left;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #848b8f;
  min-width: 150px;
}

.insurance-menu-container .menu-div {
  float: none;
}

.insurance-menu-items {
  padding: 0px;
}

.menu-container {
  width: 760px;
  padding: 2%;
  background-color: #ffffff;
  float: left;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #848b8f;
}

.menu-items {
  float: left;
}

.menu-div {
  padding: 0px;
  float: left;
  min-height: 48px;
}

.menu-div a {
  font-size: 16px;
  line-height: 20px;
  color: #192934 !important;
  font-weight: 400;
}

.menu-div a:hover {
  text-decoration: none;
  font-weight: bold;
}

.menu-div img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.buisness-menu {
  border-left: 1px solid #a6e2ef;
}

.menu-items {
  float: left;
}

.login-container {
  width: 100%;
  padding: 10% 0;
  background-image: url('..../../../../../../../../public/Content/images/Rectangle%20142.png');
  background-size: cover;
}

.login-form {
  height: auto;
  margin: 0px auto;
  padding: 30px 20% 200px 20%;
  color: white !important;
  background-image: url('..../../../../../../../../public/Content/images/login-auth.png');
  background-size: cover;
}

.login-heading {
  font-size: 48px;
}

.login-label {
  color: white !important;
  margin-top: 60px;
}

.login-button {
  background: #72be44;
  border-radius: 4px;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 103.9%;
  outline: none !important;
  outline: none;
  border: none;
  height: 40px;
  padding: 0px 20px;
  margin-top: 30px;
  color: white;
}

/* Media Query For Mobile and Desktop */

.col-md-adjsut {
  max-width: 1700px !important;
  width: 100% !important;
  margin-top: -39px;
}

.col-md-padding {
  padding: 30px 180px !important;
}

.col-md-adjsut-content {
  /* max-width: 1169px !important; */
  width: 100% !important;
}

.title-width-adjsut {
  width: auto;
}

.form-group {
  width: 100%;
}

.form-control {
  width: 100%;
}

.white-heading {
}

/* .white-heading .heading-div::after {
  border-bottom: 1px solid white !important;
  border-top: 1px solid white !important;
} */

.mt-0 {
  margin-top: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
  padding-left: 15px !important;
}

.adjust-content {
  padding: 30px 10%;
}

.adjust-header {
  padding-right: 10%;
  padding-left: 10%;
}

.nav-link.highlight-text {
  color: #cf3d96 !important;
}

.nav-link {
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #192934 !important;
  font-weight: 400;
}

.nav-link.home-link {
  color: #006d9e !important;
  font-weight: 600;
}

.nav-link:hover {
  font-weight: 700;
}

.anchor-button,
aanchor-button:hover {
  text-decoration: none !important;
  color: inherit !important;
}

select,
select option {
  color: black;
  /* font-family: Source Sans Pro; */
  font-style: normal;
  font-weight: normal;
}

select.disabled {
  color: grey !important;
}

.list-items li {
  margin-top: 0;
  margin-bottom: 1rem;
}

.dh-li {
  min-height: 39px;
  height: 100%;
  padding-top: 10px;
  font-weight: normal;
  padding-bottom: 10px;
}

.dh-ul {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 49px;
}

.dh-ul.grey {
  background-color: #f0f4f5;
}

.domestic-covered-container ul {
  padding-left: 0px;
  margin-left: 5px;
}

.domestic-covered-container ul li {
  margin-bottom: 12px !important;
}

.footer-contact {
  line-height: 34px !important;
}

.dhs-payment-container {
  margin-top: 30px;
  text-align: center;
}

.payment-confirmpage {
  margin-top: 45px;
  padding-top: 30px;
}

.promotion-highlight-text {
  color: #ed2c67 !important;
}

@media only screen and (max-width: 600px) {
  .xs-padding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs-adjust {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .bottom-btn {
    position: relative !important;
  }
  .position-reset {
    position: relative !important;
    top: 10px;
    left: 0px;
  }
  .office-info {
    background-color: white;
    display: inline-block;
    padding: 20px;
    margin-bottom: 15px;
    /* margin-bottom: 10px;
    margin-top: 19px; */
    border-radius: 12px;
    width: 100%;
  }
  .contactus-body-content-holder {
    /* height: 100%; */
    background-image: url(..../../../../../../../../public/Content/images/contactus_bg.jpg);
    padding: 60px 130px;
    background-repeat: no-repeat;
    background-color: white;
    background-size: contain;
    height: auto;
    width: 100%;
    background-size: cover;
    margin-bottom: -5%;
  }
  .contactus-body-content {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  .contactus-map {
    width: 100%;
    /* padding: 20px; */
    box-sizing: border-box;
    margin-left: 0%;
    /* margin-bottom: 50%; */
  }

  .form-control {
    margin-top: 5%;
    width: 100% !important;
  }
  .col-md-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .claiminfo-dropdown,
  claiminfo-dropdown button {
    width: 100% !important;
  }
  .footer-text-area {
    padding: 30px;
  }
  .body-bottom-content .content-holder {
    max-width: 100%;
  }
  .insurance-experts {
    font-size: 2em;
  }
  .right-box-menu-arrow {
    display: none !important;
  }
  .col-footer {
    max-width: 100%;
    margin-bottom: 10px;
    flex: unset;
  }
  .body-bottom-content-holder_BP .col {
    margin-bottom: 10px;
    min-width: 100%;
  }
  .footer-contact-holder {
    text-align: left;
  }
}
@media (min-width: 601px) and (max-width: 991px) {
  .xs-padding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs-adjust {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .bottom-btn {
    position: relative !important;
  }
  .position-reset {
    position: relative !important;
    top: 10px;
    left: 0px;
  }
  .office-info {
    background-color: white;
    display: inline-block;
    padding: 20px;
    margin-bottom: 15px;
    /* margin-bottom: 10px;
    margin-top: 19px; */
    border-radius: 12px;
    width: 100%;
  }
  .contactus-body-content-holder {
    /* height: 100%; */
    background-image: url(..../../../../../../../../public/Content/images/contactus_bg.jpg);
    padding: 60px 130px;
    background-repeat: no-repeat;
    background-color: white;
    background-size: contain;
    height: auto;
    width: 100%;
    background-size: cover;
    margin-bottom: -5%;
  }
  .contactus-body-content {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  .contactus-map {
    width: 100%;
    /* padding: 20px; */
    box-sizing: border-box;
    margin-left: 0%;
  }

  .form-control {
    margin-top: 5%;
    width: 100% !important;
  }
  .col-md-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .claiminfo-dropdown,
  claiminfo-dropdown button {
    width: 100% !important;
  }
  .footer-text-area {
    padding: 30px;
  }
  .body-bottom-content .content-holder {
    max-width: 100%;
  }
  .insurance-experts {
    font-size: 2em;
  }
  .right-box-menu-arrow {
    display: none !important;
  }
  .col-footer {
    max-width: 100%;
    margin-bottom: 10px;
    flex: unset;
  }
  .body-bottom-content-holder_BP .col {
    margin-bottom: 10px;
    min-width: 100%;
  }
  .footer-contact-holder {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1441px) {
  .xs-padding-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs-adjust {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .bottom-btn {
    position: relative !important;
  }
  .position-reset {
    position: relative !important;
    top: 10px;
    left: 0px;
  }
  .office-info {
    background-color: white;
    display: inline-block;
    padding: 20px;
    margin-bottom: 15px;
    /* margin-bottom: 10px;
    margin-top: 19px; */
    border-radius: 12px;
    width: 32%;
  }
  .contactus-body-content-holder {
    /* height: 100%; */
    background-image: url(..../../../../../../../../public/Content/images/contactus_bg.jpg);
    padding: 60px 130px;
    background-repeat: no-repeat;
    background-color: white;
    background-size: contain;
    height: 35vh;
    width: 100%;
    background-size: cover;
    margin-bottom: 350px;
  }
  .contactus-body-content {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  .contactus-map {
    width: 100%;
    /* padding: 20px; */
    box-sizing: border-box;
    margin-left: 0%;
  }

  .form-control {
    margin-top: 5%;
    width: 100% !important;
  }
  .col-md-padding {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .claiminfo-dropdown,
  claiminfo-dropdown button {
    width: 100% !important;
  }
  .footer-text-area {
    padding: 30px;
  }
  .body-bottom-content .content-holder {
    max-width: 100%;
  }
  .insurance-experts {
    font-size: 2em;
  }
  .right-box-menu-arrow {
    display: none !important;
  }
  .col-footer {
    max-width: 100%;
    margin-bottom: 10px;
    flex: unset;
  }
  .body-bottom-content-holder_BP .col {
    margin-bottom: 10px;
    min-width: 100%;
  }
  .footer-contact-holder {
    text-align: left;
  }
}

.double-underline-for-title {
  width: 100%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-top: 4px;
}

.heading-BP.white-heading {
  border-bottom: none;
}

.heading-BP.white-heading .heading-div {
  border-bottom: none;
}

.double-underline-for-sub-title {
  width: 100%;
  height: 4px;
  border-top: 1px solid #006d9e;
  border-bottom: 1px solid #ed2c67;
  margin-top: 4px;
}

.bottom-content-title_BP.heading-wrapper {
  border-bottom: none;
}

.bottom-content-title_BP.heading-wrapper .heading-div {
  border-bottom: none;
}

/** css for qna select **/

div.dropdown.bootstrap-select {
  display: block;
  width: 100% !important;
  background-color: white;
  font-weight: bold;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-menu li {
  position: relative;
  height: 37px;
}

.bootstrap-select .dropdown-menu li a span,
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-family: Arial;
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border-radius: 0px !important;
  margin-top: -2px !important;
}

.bootstrap-select .dropdown-menu li a span:focus,
.bootstrap-select .dropdown-menu li a span:focus:active {
  outline: none;
  border: none;
}

.filter-option-inner {
  font-weight: bold;
}

/**/

/*login page*/
.btn-holder {
  display: inline-block;
}
#login-spinner-holder {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.login-spinner {
  margin-left: 10px;
}

.promotion-WhatsNew-content {
  text-align: left;
  font-size: 12pt;
  font-family: Arial;
  font-weight: normal;
  color: black;
}
