.containerrr {
  width: 95%;
  margin: 0px auto;
  margin-right: 2px;
  border-radius: 10px;
  border: none;
  margin-bottom: 4px;
  box-shadow: 1px 2px 5px 6px #f7f7f8;
  overflow: visible;
}

.form-headerrr {
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-headerr h2 {
  margin: 0;
}

.form-header.closedd {
  border: none;
}

.form-bodyy {
  padding: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 4px 2px #f7f7f8;
  border: none;
  outline: none;
}

.inputs_styless_ {
  font-family: Medium;
  color: #636468;
  font-size: 13px;
  margin-left: 8px;
}
.txtinputs_stylingg {
  background-color: #F8FAFB;
  border-radius: 8px;
  width: 96%;
  padding: 5px;
  margin-left: 8px;
  margin-top: 10px;
  font-size: 13px ;
}
.css-1gnspwu-MuiGrid-root {
  background-color: #f0f0f0; 
  height: 0%;
}

