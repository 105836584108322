.dropdown-containerr {
  position: relative;
  border-radius: 10px;
 
  margin: 2px;
}

.dropdown-itemm {
  /* padding: 2px; */
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 29px;
}
.dropdown-btnn {
  background-color: #f8f8f8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* cursor: pointer; */
  z-index: 100000;
  overflow: hidden; 
  /* margin: 12px; */
  
}

.selected-valuee {
  font-family: 'Medium';
  font-size: 13px;
  color: #636468;
}

.arrow-iconn {
 
  margin-left: 5px;
  font-size: 12px;
}

.dropdown-contentt {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #f8f8f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 3px 10px;
  cursor: pointer;
  transition: height 0.1s ease;
  font-family: 'Medium';
  font-size: 13px;
  color: '#636468';
  z-index: 100000;
  max-height: 120px; 
  overflow-y: auto; 
}
.dropdown-contentt::-webkit-scrollbar {
  width: 10px;
}

.dropdown-contentt::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

.dropdown-contentt::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}


.dropdown-containerr.open .dropdown-contentt {
  height: auto;
}

.placeholderr {
  font-family: 'Medium';
  font-size: 13px;
  color: '#636468';
 
}

.dropdown-containerr:not(.open) .dropdown-btnn {
  border-radius: 8px;
}

.label,
.icon {
display: flex;
align-items: center;
justify-content: center;
height: 100%; 
}