.maincontainer_productPlans_imageTab {
  display: flex;
  justify-content: center;
}

.card_container_productplans_imageTab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  box-sizing: border-box;
}

.image_container_productPlans_imagetab {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.image_styling_productPlans_imageTab {
  display: flex;
  width: 100%;
  height: auto;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin-right: 1%;
  border-radius: 12px;
}

.card_content_container_productPlans_imageTab {
  width: 100%;
  margin-top: 10px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.card_timelimit_styling_productPlans_imageTab,
.card_planname_styling_productPlans_imageTab,
.card_priceLimit_styling_productPlans_imageTab {
  display: block;
  margin-top: 5px;
}

.card_timelimit_styling_productPlans_imageTab {
  font-size: 10px;
  font-weight: bold;
}

.card_planname_styling_productPlans_imageTab {
  font-size: 16px;
  font-weight: bold;
  color: #002d8c;
}

.card_priceLimit_styling_productPlans_imageTab {
  font-size: 14px;
  color: #000;
}

.explore_container_productPlans_imageTab {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #002d8c;
  font-weight: bold;
  margin-left: 1.5%;
}

.explore_text_productPlans_imageTab {
  font-size: 13px;
}

.explore_icon_productPlans_imageTab {
  font-size: 14px;
  margin-left: 4px;
}

/* @media screen and (max-width:600px) {
  .maincontainer_productPlans_imageTab {
    display: flex;
    justify-content: center;
  }
  
} */
@media (min-width: 601px) and (max-width: 945px) {
  .maincontainer_productPlans_imageTab {
    display: flex;
    justify-content: start;
  }
}
/* @media (min-width:921px) and (max-width:1280px) {
  .maincontainer_productPlans_imageTab {
    display: flex;
    justify-content: center;
  }
  
} */
@media screen and (max-width: 767px) {
  .image_container_productPlans_imagetab {
    width: 100%;
    height: 100%;
    /* height: 150px; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
  }
}
