.img-containerrr-navbarrr {
  display: flex;
  justify-content: start;
  margin-left: 15px;
}
.imagesizedifferscreen_navbarr {
  width: 30%;
  cursor: pointer;
}
.text-containerrrr-navbarrrr {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.optionsss-styling-navbarrr {
  font-size: 13px;
  font-family: Medium;
  cursor: pointer;
}
.buttonStyling_navbarrr {
  border-radius: 8px;
  color: #133c94;
  background-color: #ffffff;
  min-width: 50px;
  margin-right: 5px;
  padding: 4px 10px 4px 10px;
  font-family: Medium;
  border: 1px solid #133c94;
}
.buttonStyling_navbarrr_agentportal {
  border-radius: 8px;
  color: #ffffff;
  background-color: #002d8c !important;
  min-width: 50px;
  /* margin-right: 5px; */
  padding: 4px 5px 4px 5px;
  font-family: Medium;
}
.buttonStyling_navbarrr_agentportal:hover {
  background-color: #002d8c !important;
  color: #ffffff;
}
.optionsss-styling-navbarrr.selectedddd-route-text {
  color: #002d8c;
  /* font-weight: 600; */
  font-size: 13px;
}
.img-containerrr-navbarrr.selectedddd-route-text {
  color: #002d8c;
  /* font-weight: 600; */
  font-size: 13px;
}

@media screen and (max-width: 600px) {
  .img-containerrr-navbarrr {
    display: flex;
    justify-content: start;
    margin-left: 5%;
    /* margin-right: 5%; */
  }
  .imagesizedifferscreen_navbarr {
    width: 60%;
    cursor: pointer;
  }
  .text-containerrrr-navbarrrr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
  }
  .buttonStyling_navbarrr {
    border-radius: 5px;
    color: #133c94;
    background-color: #ffffff;
    /* min-width: 30px; */
    margin-right: 10px;
    /* padding: 2px 2px 2px 2px; */
    font-family: Medium;
    font-size: 10px;
    border: 1px solid #133c94;
  }
  .buttonStyling_navbarrr_agentportal {
    border-radius: 8px;
    color: #ffffff;
    background-color: #002d8c !important;
    min-width: 30px;
    margin-right: 10px;
    padding: 4px 8px 4px 8px;
    font-family: Medium;
    font-size: 13px;
  }
  .buttonStyling_navbarrr_agentportal:hover {
    background-color: #002d8c !important;
    color: #ffffff;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
    margin-right: 5%;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .img-containerrr-navbarrr {
    display: flex;
    justify-content: start;
    margin-left: 5%;
    /* margin-right: 5%; */
  }
  .imagesizedifferscreen_navbarr {
    width: 50%;
    cursor: pointer;
  }
  .text-containerrrr-navbarrrr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
  }
  .buttonStyling_navbarrr {
    border-radius: 5px;
    color: #133c94;
    background-color: #ffffff;
    min-width: 30px;
    margin-right: 5px;
    padding: 4px 8px 4px 8px;
    font-family: Medium;
    font-size: 13px;
    border: 1px solid #133c94;
  }
  .buttonStyling_navbarrr_agentportal {
    border-radius: 4px;
    color: #ffffff;
    background-color: #002d8c !important;
    min-width: 30px;
    margin-right: 5px;
    padding: 4px 8px 4px 8px;
    font-family: Medium;
    font-size: 13px;
  }
  .buttonStyling_navbarrr_agentportal:hover {
    background-color: #002d8c !important;
    color: #ffffff;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
    margin-right: 5%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .img-containerrr-navbarrr {
    display: flex;
    justify-content: center;
    /* margin-right: 5%; */
  }
  .imagesizedifferscreen_navbarr {
    width: 70%;
    cursor: pointer;
  }
  .text-containerrrr-navbarrrr {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
  }
  .buttonStyling_navbarrr {
    border-radius: 5px;
    color: #133c94;
    background-color: #ffffff;
    min-width: 30px;
    margin-right: 10px;
    padding: 4px 8px 4px 8px;
    font-family: Medium;
    font-size: 13px;
    border: 1px solid #133c94;
  }
  .buttonStyling_navbarrr_agentportal {
    border-radius: 8px;
    color: #ffffff;
    background-color: #002d8c !important;
    min-width: 30px;
    margin-right: 10px;
    padding: 4px 8px 4px 8px;
    font-family: Medium;
    font-size: 13px;
  }
  .buttonStyling_navbarrr_agentportal:hover {
    background-color: #002d8c !important;
    color: #ffffff;
  }
  .optionsss-styling-navbarrr {
    font-size: 13px;
    font-family: Medium;
    cursor: pointer;
    margin-right: 5%;
  }
}
