/* .imageCard_mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}
.headingContainer_imageCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}
.always_heading_imagecard {
  font-family: Medium;
  font-size: 25px;
  color: #012d87;
}
.secondary_heading_imagecard {
  font-family: 'Heavy', sans-serif; 
  font-size: 25px;
  color: #012d87;
  margin-left: 10px;
}
.image_container_imagecard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1%;
  margin-bottom: 5%;
  width: 80%;
}
.container_images_imagecard {
  position: relative;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-left: 2%;
}
.image_imageCard {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: 12px;
  top: 5%;
  right: 0;
}
.underImage_heading_imageCard {
  border-radius: 12px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1.5%;
  flex-direction: column;
  color: #ffffff;
  position: relative;
  z-index: 2;
  width: 100%;
}
.positioning_image_undercard_imagecard {
  display: flex;
  width: 90%;
  position: absolute;
  top: 75%;
}
.first_heading_imagecard {
  font-family: 'Heavy', sans-serif; 
  font-size: 16px;
  margin-left: 5%;
}
.second_heading_imagecard {
  font-family: Medium;
  font-size: 12px;
  margin-left: 5%;
}
.image___container_imagecard {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 2%;
  padding: 5px;
  width: 100%;
  height: '300px';
}

@media (max-width: 600px) {
  .image_container_imagecard {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
  }
  .image_imageCard {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 12px;
  }
  .container_images_imagecard {
    display: flex;
    justify-content: center;
    width: 80%;
    height: auto;
    margin-top: 2%;
    margin-left: 0;
    margin-bottom: 2%;
  }
  

  .underImage_heading_imageCard {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .positioning_image_undercard_imagecard {
    display: flex;
    width: 90%;
    position: absolute;
    align-items: center;
  }
  .container_images_imagecard {
    display: flex;
    justify-content: start;
    width: 100%;
    height: auto;
  }
  .imageCard_mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .image___container_imagecard {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 2%;
    padding: 5px;
    width: 100%;
  }
  .first_heading_imagecard {
    font-family: Medium;
    font-size: 12px;
    margin-left: 5%;
    width: 100%;
  }
  .second_heading_imagecard {
    font-family: Medium;
    font-size: 10px;
    margin-left: 5%;
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 769px) {
  .image_container_imagecard {
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
    width: 100%;
  }
  .container_images_imagecard {
    display: flex;
    justify-content: center;
    width: 80%;
    height: auto;
    margin-top: 2%;
    margin-left: 0;
    margin-bottom: 2%;
  }
  .image_imageCard {
    width: 90%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border-radius: 12px;
    top: 35%;
    right: 0;
  }

  .underImage_heading_imageCard {
    width: 90%;
    border-radius: 8px;
    position: relative;
  }
  .positioning_image_undercard_imagecard {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
  .container_images_imagecard {
    display: flex;
    justify-content: start;
    height: auto;
  }
  .imageCard_mainContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .image___container_imagecard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    padding: 5px;
    width: 100%;
    height: 120px;
  }
  .first_heading_imagecard {
    font-family: 'Heavy', sans-serif; 
    font-size: 12px;
    margin-left: 5%;
  }
  .second_heading_imagecard {
    font-family: Medium;
    font-size: 10px;
    margin-left: 5%;
  }
} */

/* .imageCard_mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.headingContainer_imageCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}

.always_heading_imagecard {
  font-family: Medium;
  font-size: 25px;
  color: #012d87;
}

.secondary_heading_imagecard {
  font-family: 'Heavy', sans-serif;
  font-size: 25px;
  color: #012d87;
  margin-left: 10px;
}

.image_container_imagecard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1%;
  margin-bottom: 5%;
  width: 100%;
  height: 100%;
}

.container_images_imagecard {
  position: relative;
  width: 30%;
  height: 320px;
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-left: 2%;
}

.image_imageCard {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border-radius: 12px;
  top: 5%;
  right: 0;
}

.underImage_heading_imageCard {
  border-radius: 12px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1.5%;
  flex-direction: column;
  color: #ffffff;
  position: relative;
  z-index: 2;
  width: 100%;
}

.positioning_image_undercard_imagecard {
  display: flex;
  width: 90%;
  position: absolute;
  top: 75%;
}

.first_heading_imagecard {
  font-family: 'Heavy', sans-serif;
  font-size: 16px;
  margin-left: 5%;
}

.second_heading_imagecard {
  font-family: Medium;
  font-size: 12px;
  margin-left: 5%;
}

.image___container_imagecard {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 2%;
  padding: 5px;
  width: 100%;
  height: 300px;
}

@media (max-width: 600px) {
  .image_container_imagecard {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    width: 100%;
  }
  .container_images_imagecard {
    width: 80%;
    height: auto;
    margin-left: 0;
  }
  .image_container_imagecard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 1%;
    margin-bottom: 5%;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .image_container_imagecard {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
    width: 100%;
    height: 100%;
  }
  .container_images_imagecard {
    width: 80%;
    height: auto;
    margin-left: 0;
  }
  .image_container_imagecard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 1%;
    margin-bottom: 5%;
    width: 100%;
    height: 100%;
  }
} */

.imageCard_mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.headingContainer_imageCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}

.always_heading_imagecard {
  font-family: Medium;
  font-size: 30px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 15px;
}

.secondary_heading_imagecard {
  font-family: 'Heavy', sans-serif;
  font-size: 25px;
  color: #012d87;
  margin-left: 10px;
}

.image_container_imagecard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 1%;
  margin-bottom: 5%;
}

.container_images_imagecard {
  position: relative;
  width: 100%;
  margin: 2% 0;
  display: flex;
  justify-content: center;
}

.image_imageCard {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}

.underImage_heading_imageCard {
  border-radius: 12px;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 1.5%;
  flex-direction: column;
  color: #ffffff;
  position: relative;
  z-index: 2;
  width: 100%;
}

.positioning_image_undercard_imagecard {
  display: flex;
  width: 90%;
  position: absolute;
  top: 75%;
}

.first_heading_imagecard {
  font-family: 'Heavy', sans-serif;
  font-size: 16px;
  margin-left: 5%;
}

.second_heading_imagecard {
  font-family: Medium;
  font-size: 13px;
  margin-left: 5%;
}

.image___container_imagecard {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
  width: 100%;
}
@media (max-width: 450px) {
  .image_container_imagecard {
    flex-direction: column;
  }

  .container_images_imagecard {
    width: 100%;
    height: auto;
  }

  .positioning_image_undercard_imagecard {
    top: 55%;
    width: 95%;
  }
}

@media (min-width: 451px) and (max-width: 600px) {
  .image_container_imagecard {
    flex-direction: column;
  }

  .container_images_imagecard {
    width: 100%;
    height: auto;
  }

  .positioning_image_undercard_imagecard {
    top: 65%;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .image_container_imagecard {
    flex-direction: column;
    align-items: center;
  }

  .container_images_imagecard {
    width: 80%;
    height: auto;
  }

  .positioning_image_undercard_imagecard {
    top: 75%;
  }
}

@media (min-width: 961px) {
  .image_container_imagecard {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }

  .container_images_imagecard {
    width: 30%;
    height: auto;
    margin: 1%;
  }
  .positioning_image_undercard_imagecard {
    top: 70%;
  }
}
