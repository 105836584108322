.main_mainContainer_products {
  background-color: #014fef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100vw;
}
.our_product_heading_container_products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.our_heading_products {
  font-family: Medium;
  color: #ffffff;
  margin-right: 1%;
}
.products_heading_products {
  font-size: 30px;
  font-family: Medium;
  color: #ffffff;
  margin-top: 5%;
}
.imageContainer_products {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  margin-top: 10px;
}
.image____products {
  width: 60%;
  height: 60%;
  object-fit: cover;
  z-index: 2;
}
.Products_Heading_Styling {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 4%;
  z-index: 2;
  margin-left: 9%;
  cursor: pointer;
}
.productname_styling_products {
  font-size: 16px;
  color: #ffffff;
}
.icon_styling_products {
  font-size: 34px;
  color: #ffffff;
}
.productname_icon_styling {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-right: 15%;
  font-size: 16px;
}
.description_textStyling {
  color: #5fffdb;
  font-size: 13px;
  font-family: Medium;
  width: 100%;
}
.icon_styling_products_after {
  font-size: 34px;
  color: #5fffdb;
}
.productname_styling_products_after {
  font-size: 16px;
  color: #5fffdb;
  font-family: Medium;
}

@media (max-width: 600px) {
  /* .productname_styling_products_after {
    font-size: 24px;
    color: #5fffdb;
    font-family: Medium;
    font-size: bold;
    width: 80%;
  }
  .productname_icon_styling {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
  }
  .Products_Heading_Styling {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 4%;
    z-index: 2;
    cursor: pointer;
    width: 100%;
  } */
  .productname_icon_styling {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 15%;
    font-size: 16px;
  }
  .Products_Heading_Styling {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    z-index: 2;
    margin-left: 5%;
    margin-right: 5%;
    cursor: pointer;
  }
  .icon_styling_products {
    font-size: 34px;
    color: #ffffff;
    margin-right: -12px;
  }
  .icon_styling_products_after {
    font-size: 34px;
    color: #5fffdb;
    margin-right: -12px;
  }
  .description_textStyling {
    color: #5fffdb;
    font-size: 13px;
    font-family: Medium;
    width: 100%;
    margin-top: 2%;
  }
  .imageContainer_products {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }
  .image____products {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .Products_Heading_Styling {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    z-index: 2;
    margin-left: 5%;
    cursor: pointer;
  }
  .description_textStyling {
    color: #5fffdb;
    font-size: 13px;
    font-family: Medium;
    width: 90%;
  }
  .imageContainer_products {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }
  .image____products {
    width: 100%;
    height: 60%;
    object-fit: cover;
    z-index: 2;
  }
}

@media (min-width: 961px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1440px) {
}
