@font-face {
    font-family: 'Black';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueBlack.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BlackItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueBlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Bold';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'BoldItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Heavy';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueHeavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'HeavyItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueHeavyItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Italic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Light';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'LightItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Medium';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MediumItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueMediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roman';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueRoman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Thin';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueThin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'ThinItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueThinItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'UltraLight';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueUltraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'UltraLightItalic';
    src:url('../../assets/fonts/HelveticaNeueFonts/HelveticaNeueUltraLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Aptos Italic';
    src: url('../../assets/fonts/giloryFonts/fonnts.com-aptos-italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
  }
 